import { ENVIRONMENT_NAME_PROD } from "../common/models/constants.model";

export const environment = {
  production: true,
  displayName: ENVIRONMENT_NAME_PROD,
  authClientId: "a6d2f76b-1431-4005-86bb-b4dcdb8570af",
  baseServiceUrl: "https://ppr.jmt.com",
  costReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSectiond95ae812292da80d19be?filter=Projects/Project_x0020_Id eq ",
  revenueReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSectiond95ae812292da80d19be?filter=Projects/Project_x0020_Id eq ",
  estimatedWPCReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSectiond95ae812292da80d19be?filter=Projects/Project_x0020_Id eq ",
  contractMBEGoalReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/d2b82c16-96ba-44f0-b473-8efc4d575523/ReportSectionbccf3677372d16cbc80e?filter=Contracts/Contract_x0020_Id eq ",
  unbilledReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSection8a52c35122d8156a98d0?filter=Projects/Project_x0020_Id eq ",
  unpaidReportUrl: "https://app.powerbi.com/groups/me/apps/35a5aec1-bb1e-4c2d-9018-c3072bbe59a1/reports/50486645-8245-485c-b68a-370b466a2dbd/ReportSection8a52c35122d8156a98d0?filter=Projects/Project_x0020_Id eq ",
  enableGoogleAnalytics: true,
  googleAnalyticsId: "G-DFRDJS95X5"
};
